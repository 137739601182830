import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";

const Main = React.lazy(() => import("./main/main"));

export default function App() {
  
  return (
    <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<>...</>}>
                <Main />
              </React.Suspense>
            }
          />
          <Route path="*" element={<NoMatch />} />
      </Routes>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}